import React from 'react'
import '../styles/Result.css'

const Result = ({ results, setResults }) => {
    const correctResults = results.reduce((total, result) => result ? total + 1 : total, 0)
    const totalResults = results.length

    const getLevel = () => {
        const levels = ["JarJar","Ewok","C3PO","ObiWan","Yoda"]
        const levelIndex = Math.floor(correctResults / totalResults * (levels.length - 1))
        return levels[levelIndex]
    }

    const tryAgain = () => {
        setResults(() => [])
    }

    const level = getLevel()

    return (
        <div className="Result--container">
            <h1 className="Result--score">Score: {correctResults} of {totalResults}</h1>
            <p className={`Result--level Result--level-${level}`}>Level: {level}</p>
            <button className="Results--button-retry" onClick={tryAgain}>Try again</button>
        </div>
    )
}

export default Result

import React from 'react'
import '../styles/Error.css'

const Error = ({ error }) => (
    <section className="Error-container">
        <h1 className="Error-message">Something went wrong</h1>
        <p className="Error-comment">Try using the force or something.</p>
    </section>
)

export default Error

import React, { useState } from 'react'

import Header from './Header'
import Quiz from './Quiz'
import Result from './Result'
import Progress from './Progress'
import Error from './Error'

function App() {
  const [results, setResults] = useState([])
  const [error, setError] = useState(false)
  const gameLength = 10

  return (
    <div className="App">
        <Header />
        {!error ? <>
          { results.length < gameLength &&
            <Quiz results={results} setResults={setResults} setError={setError}/>
          }
          { results.length === gameLength && 
            <Result results={results} setResults={setResults} />
          }
          <Progress results={results} gameLength={gameLength} />
          </>
          : <Error error={error}/>}
    </div>
  );
}

export default App;

import React from 'react'
import '../styles/Progress.css'

const Progress = ({ results, gameLength }) => {
    const barLength = results.length / gameLength
    return (
        <div className="Progress--bar" style={{
            "--bar-length": barLength, 
        }}></div>
    )
}

export default Progress

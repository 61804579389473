// some fields lead to ugly or stupid questions; let's remove them
// (note these are applied to instance fields only; not to the top level resources)
const undesiredFields = [
    // these fields have arrays as value; we want only single answers
    // remove for now until I write logic to ask "name one of" questions
    "residents",
    "films",
    "starships",
    "vehicles",
    "characters",
    "episode_id",
    "planets",
    "species",
    "pilots",
    "residents",
    "hair_colors",
    "eye_colors",
    "skin_colors",
    "people",
    // these fields are irrelevant
    "url",
    "created",
    "edited",
    // these are boring
    "gender",
    "designation",
    // these fields have url values
    // remove for now until I write recursive logic to follow them
    "species",
    "homeworld",
    // what is this even?
    "MGLT",
    // don't ask for the title or name; these are given already
    "name",
    "title"
]

export const filterFields = (fields) => {
    const filteredFields = fields.filter((field) => !undesiredFields.includes(field))
    return filteredFields
}

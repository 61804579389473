import React from 'react'
import '../styles/Header.css'

const Header = () => (
    <header className="App--header">
        <h1 className="App--title">StarWars PubQuiz</h1>
    </header>
)

export default Header

import React, { useState, useEffect } from 'react'
import '../styles/Quiz.css'

import axios from 'axios'
import classNames from 'classnames'

import { massageProperty, massageType, massageAnswer } from '../helpers/massageLanguage'
import { filterFields } from '../helpers/filterFields'

import Loader from './Loader'

const Quiz = ({results, setResults, setError}) => {
    const baseUrl = 'https://swapi.dev/api/'

    const [loading, setLoading] = useState(true)
    const [type, setType] = useState(null)
    const [property, setProperty] = useState(null)
    const [instance, setInstance] = useState(null)
    const [answers, setAnswers] = useState([])
    const [selectedAnswer, setSelectedAnswer] = useState(null)
    const [rightAnswer, setRightAnswer] = useState('')
    const [revealAnswer, setRevealAnswer] = useState(false)

    const getRandomInstance = async (resource) => {
        // we pick a random instance of this resource type
        const randomInstanceId = Math.floor(Math.random() * resource.count) + 1

        try {
            const response = await axios.get(baseUrl + resource.name + '/' + randomInstanceId)
            return response.data
        } catch(e) {
            // There's a lot of bad data on this API!
            // Many ids give a 404; if so we just try again
            if (e.response.status === 404) {
                // NOTE: here be dragons
                // this could conceivably yield an infinite loop, 
                // but only if all instances of this resource 404
                console.log('404 for id ' + randomInstanceId + ', trying again...')
                return getRandomInstance(resource)
            } else {
                setError(true)
                return
            }
        }

    }

    const createQuestion = async () => {
        // first we retrieve the possible resources
        // (we don't hardcode because the starwars universe might expand)
        let resourcesData
        try {
            const { data } = await axios.get(baseUrl)
            resourcesData = data
        } catch(e) {
            setError(true)
            return
        }

        // then we pick a resource randomly
        const resourceNames = Object.keys(resourcesData)
        const randomResouceIndex = Math.floor(Math.random() * resourceNames.length)
        const resource = {
            name: resourceNames[randomResouceIndex],
            count: null
        }

        // we need to know how many instances there are of this resource type
        // it would have been convenient if this were on the resource data
        // but it isn't, so let's do that ourselves
        let resourceData
        try {
            const { data } = await axios.get(baseUrl + resource.name)
            resourceData = data
        } catch(e) {
            setError(true)
            return
        }
        resource.count = resourceData.count

        // and set the type to it
        setType(() => resource)
        
        const instance = await getRandomInstance(resource)
        setInstance(() => instance)

        // now we pick a random field from that instance
        const fields = Object.keys(instance)
        const filteredFields = filterFields(fields)
        const randomFieldIndex = Math.floor(Math.random() * filteredFields.length)
        const field = filteredFields[randomFieldIndex]
        // and we save the property to it
        setProperty(() => field)
    }

    const createAnswers = async () => {
        // first we start with the right answer
        const rightAnswer = instance[property]
        setRightAnswer(() => rightAnswer)

        // now we find 3 wrong answers
        const answers = []
        // we do this by asking for the values of other instances of the same type
        for (let i = 0; i < 3; i++) {
            // note this may be the same as the original id, but we're going to filter duplicates anyhow
            const otherInstance = await getRandomInstance(type)
            answers.push(otherInstance[property])
        }

        // now add the right answer at a random place
        const randomIndex = Math.floor(Math.random() * 4)
        answers.splice(randomIndex, 0, rightAnswer)

        // remove duplicate answers by brute force
        // (a future version could do another API call for a different answer)
        const uniqueAnswers = [...new Set(answers)]

        setAnswers(() => uniqueAnswers)
        setLoading(() => false)
    }

    const selectAnswer = (answer) => {
        if (!revealAnswer) {
            setSelectedAnswer(() => answer)
            setRevealAnswer(() => true)
            setTimeout(() => {
                setResults((prevResults) => [
                    ...prevResults,
                    checkAnswer(answer)
                ])
            }, 500)
        }
    }

    const checkAnswer = (answer) => {
        return answer === rightAnswer
    }

    useEffect(() => {
        // on change in results, reset everything & pose another question
        setLoading(true)
        setType(null)
        setProperty(null)
        setInstance(null)
        setAnswers([])
        setSelectedAnswer(null)
        setRightAnswer('')
        setRevealAnswer(false)        
        
        createQuestion()
    }, [results])

    useEffect(() => {
        // trigger finding the answers when everything is set by the question
        if (type && property && instance) {
            createAnswers()
        }
    }, [type, property, instance])

    return (
        <section className="Quiz--container">
            { loading ? <Loader /> :
                <>
                    <section className="Quiz--question">
                        <p>What is the {massageProperty(property)} of the {massageType(type.name)} {instance.name || instance.title}?</p>
                    </section>
                    <section className="Quiz--answers">
                        {answers.map((answer, i) => (
                            <label className={
                                classNames({
                                    "Quiz--answer": true,
                                    "selected": answer === selectedAnswer,
                                    "correct": revealAnswer && checkAnswer(answer),
                                    "incorrect": revealAnswer && !checkAnswer(answer)
                                })
                            } key={i}>
                                <input onClick={() => selectAnswer(answer)} className="Quiz--answer_checkbox" type="checkbox" />
                                {massageAnswer(answer)}
                            </label>
                        ))}
                    </section>
                </>
            }
        </section>
    )
}

export default Quiz

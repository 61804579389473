import pluralize from 'pluralize'

export const massageType = (typeName) => {
    return pluralize.singular(typeName)
}

export const massageProperty = (propertyName) => {
    return propertyName.replaceAll('_', ' ')
}

export const massageAnswer = (answer) => {
    if (answer === 'undefined' || 
        answer === 'none' || 
        answer === 'n/a' ||
        answer === 'unknown') {
        return 'Is this a trick question?'
    }
    
    const words = answer.split(' ')
    if (words.length > 15) {
        const shortAnswer = words.slice(0,15).join(' ') + '...'
        return shortAnswer
    }

    return answer
}
